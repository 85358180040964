<template>
    <div class="container">
        <div class="text-center mt-5">
            <!-- gray cyan gray -->
            <!-- <b-spinner class="m-2" variant="secondary" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="secondary" type="grow" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="info" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="info" type="grow" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="secondary" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="secondary" type="grow" label="Spinning"></b-spinner> -->
            <!-- colorful version!! -->
            <!-- <b-spinner class="m-2" variant="primary" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="primary" type="grow" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="secondary" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="secondary" type="grow" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="danger" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="danger" type="grow" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="warning" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="warning" type="grow" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="success" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="success" type="grow" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="info" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="info" type="grow" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="dark" label="Spinning"></b-spinner>
            <b-spinner class="m-2" variant="dark" type="grow" label="Spinning"></b-spinner> -->

            <!-- Olympic Rings -->
            <b-spinner style="width: 3rem; height: 3rem; margin-right: 5px;" variant="primary" label="Spinning"></b-spinner>
            <b-spinner style="width: 3rem; height: 3rem; margin-right: 5px;" variant="dark" label="Spinning"></b-spinner>
            <b-spinner style="width: 3rem; height: 3rem; margin-right: 0;" variant="danger" label="Spinning"></b-spinner>
        </div>
        <div class="text-center">
            <b-spinner style="width: 3rem; height: 3rem; margin-right: 5px; margin-top: -25px;" variant="warning" label="Spinning"></b-spinner>
            <b-spinner style="width: 3rem; height: 3rem; margin-right: 0; margin-top: -25px;" variant="success" label="Spinning"></b-spinner>
        </div>
        <div class="text-center mt-3">
            <h5>Loading...</h5>
        </div>
    </div>
</template>
<script>
export default { // do not need to pass in this prop at the moment. can uncomment in future if needed
    // props: ['isLoading'],
    // data() {
    //     return {
    //         isLoading: this.isLoading,
    //     };
    // },
};
</script>