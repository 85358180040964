<template>
  <b-container fluid="xs"  class="shadow-sm p-4 bg-white rounded">
    <h1>Welcome to RobotReviewer LIVE!</h1>


    <p>RobotReviewer LIVE is an interface to produce semi-automatic, living, systematic reviews</p>


    <p>We are funded by the UK Medical Research Council (MRC), through its Skills Development Fellowship program (MR/N015185/1), and the US National Institutes of Health (NIH) under the National Library of Medicine (grant R01-LM012086-01A1, "Semi-Automating Data Extraction for Systematic Reviews"). Wallace is also supported in this work through an NSF CAREER award (1750978, "CAREER: Structured Scientific Evidence Extraction: Models and Corpora").</p>

    <p>Prior support came from NIH grant 5UH2CA203711-02, "Crowdsourcing Mark-up of the Medical Literature to Support Evidence-Based Medicine and Develop Automated Annotation Capabilities".</p>


  </b-container>

</template>


<script>
export default {
  name: "About",
  components: {}
};

</script>

<style scoped>
</style>
