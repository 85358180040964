<template>
  <div>
    <AbstractList />
  </div>
</template>


<script>
import AbstractList from "../components/AbstractList.vue";

export default {
  name: "ScreenAbstracts",
  components: {
    AbstractList,
  },
};

</script>

<style scoped>
</style>
