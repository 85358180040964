<template>
    <div>
        <CreateSummaryForm />
    </div>
</template>
<script>
import CreateSummaryForm from "../components/CreateSummaryForm.vue";
export default {
    name: "CreateSummary",
    components: {
        CreateSummaryForm,
    },
};
</script>
<style scoped>
</style>